.bgColor {
  background-color: #f5f5f5;
}

body::after{
  position:absolute; width:0; height:0; overflow:hidden; z-index:-1; 
  content:url(../../assets/background1.png);   
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center/cover no-repeat fixed;
    transition: background-image 0.5s; 
    z-index: 0;
  }
  
  .res-bg {
    background-image: url('../../assets/background1.png');
    z-index: 1;
  }
  
  .coastal-bg {
    background-image: url('../../assets/background2.png');
    z-index: 1;
  }

.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.logo {
    max-width: 20%; 
    height: auto; /* Ensures the aspect ratio is maintained */
}

.form {
    margin-top: 70px;
    height: 336px;
    width: 696px;
    position: relative;
}

.tab1, .tab2 {
    border-radius: 15px 15px 0px 0px;
    height: 59px;
    position: absolute;
    top: 0;
    transition: color 0.5s, background-color 0.5s;
}

.tab1 {
    left: 0;
    width: 115px;
}

.tab2 {
  left: 119px;
  top: 0;
  width: 145px;
}

.tabEnabled {
    background-color: #ffffff;
    color: #110229;
}

.tabTextEnabled, .tabTextDisabled {
    font-family: "Lufga";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2.8px;
    line-height: 36px;
}

.tabTextDisabled {
    color: rgb(0, 0, 0);
}

.selectionBox {
    -webkit-backdrop-filter: blur(20px) brightness(100%);
  backdrop-filter: blur(20px) brightness(100%);
  background-color: #ffffffcc;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0px 30px 60px -15px #8e90bc26;
  height: 102px;
  left: 0;
  position: absolute;
  top: 39px;
  width: 696px;
}

.search-button {
    background: url(../../assets/search-button.png) center no-repeat;
    background-size: 28px;
    background-color: #3b9eda;
    border-radius: 15px;
    height: 54px;
    width: 57px;
    right: 20px;
    position: absolute;
    top: 26px;
}

.search-button:hover {
  background-color: #2889c5;
}

.search-type {
    color: #110229;
  font-family: "Lufga", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 36px;
  position: absolute;
  top: 0;
  width: 98px;
}

.district {
    top: 20px;
  left: 33px;
}

.project {
    top: 20px;
  left: 300px;
}

.districtSelect {
  font-family: "Lufga", Helvetica;
  font-size: 16px;
  font-weight: 400;
  border: none!important;
  left: 38px;
  width: 200px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  bottom: 10px;
  text-align: left;
}

.projectSelect {
  font-family: "Lufga", Helvetica;
  font-size: 16px;
  font-weight: 400;
  border: none!important;
  right: 100px;
  width: 300px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  bottom: 10px;
  text-align: left;
}

.custom-select {
    width: 220px; /* Increase the width to make it larger */
    padding: 10px;
    background: white;
    border: none; /* Remove borders */
    border-radius: 10px;
    color: #000;
  }

  /* Style for the options (dropdown items) */
  .custom-select option {
    font-size: 16px; /* Increase the font size to make options larger */
  }

  /* Style for the placeholder text (in the field and dropdown) */
  .custom-select::placeholder,
  .custom-select option[disabled] {
    color: #888;
  }

  .custom-select:focus-visible {
    border: none;
  }

  .fade {
    animation: fade-out 0.4s ease-out;
  }

  .fadeBg {
    animation: fade-out 0.6s ease-out;
  }

  @media (max-width:768px) {
    .form {
      transform: scale(0.5);
      -webkit-text-size-adjust: none;
      -moz-text-size-adjust: none;
    }
    .logo {
      max-width: 40%;
    }

    .toast {
      max-width: 200px;
      max-height: 50px;
      font-size: 12px;
    }
  }

  @keyframes fade-out {
    0% {
        opacity: 1;
        transform: translateY(0%);
    }
    100% {
        opacity: 0;
        transform: translateY(-1%);
    }
}