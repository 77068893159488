
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body {
    background-color: #f5f5f5;
}

form {
    width: 100%;
}

.top {
    font-family: 'Poppins', Helvetica;
    display: flex;
    justify-content: center;
    min-width: 600px;
}

.progIndicators {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.circle {
    width: 12px;
    height: 12px;
    border: 1px solid gray;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
}

.indicatorEnabled {
    border: 1px solid #007AFF;
    background-color: #007AFF;
    transition: background-color 0.3s ease;
}

hr {
    float: right;
    width: 40px;
    border: 1px solid #B9B9C3;
    margin: 8px 0;
}

.hrspacer {
    padding-left: 60px;
}

h1 {
    font-weight: 700;
    color: #242731;
    font-size: 32px;
    line-height: 36px;
    margin-top: 20px;
    margin-bottom: 16px;
}

h2 {
    color: #242731;
    margin-top: 0px;
    margin-bottom: 5px;
}

.subtitle {
    font-family: 'Poppins', serif;
    color: #575F6E;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
}

.description {
    font-weight: 300;
    font-size: 12px;
    color: #575F6E;
    margin-top:0;
    margin-bottom: 32px
}

.column {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 30px;
    width: 570px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
 padding: 32px; 
 margin-top: 32px;
 border: 1px solid #E2E4E5;
 border-radius: 8px;
 align-items: start;
}

.inputRow {
    display: flex;
    width: 100%;
}

.daySelect {
    max-height: 40px;
    max-width: 80px;
    margin-right: 10px;
    display: inline-block;
}

#dobYear {
    margin-top: 5px;
}

.dobRow {
    display: flex;
    /* justify-content: space-around; */
}

.buttonRow {
    display: flex;
    width: 635px;
    justify-content: space-between;
    margin-bottom: 40px;
}

.buttonRow button:hover{
    background-color: #f5f5f5;
}

.icon {
    height: 24px;
    width: 24px;
    margin-right: 10px;
}

label {
    font-family: 'Poppins', Helvetica;
    color: #242426;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-bottom: 5px;
    text-align: left;
}
.spacer {
    margin-bottom: 20px;
}

.input[type="text"] {
    border: none;
}

input.numField::-webkit-outer-spin-button,
input.numField::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number].numField {
    -moz-appearance: textfield;
}

input[type="text"], input[type="email"], input[type="number"] {
 width: 100%;
 font-size: 14px;
 padding: 8px;
 margin-bottom: 20px;
 background-color: #f5f5f5;
 border: none;
 border-bottom: 1px solid #E2E4E5;
 border-radius: 4px;
 outline: none;
}

input:hover {
    border-color: hsl(0, 0%, 70%);
}

input:focus {
    border-color: hsl(0, 0%, 50%);
}

.bottomFormRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
 

button {
    display: flex;
    justify-content: center;
    width: 150px;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    margin-top: 32px;
    padding-block: 12px;
    border: 1px solid #BBBFC1;
    border-radius: 5px;
    color: #242731;
    background-color: white;
    transition: background-color 0.3s ease;
}

.submitButton {
    color: white;
    background-color: #007AFF;
    border: none;
}

.submitButton:hover{
    background-color: #0563c7!important;
}

.tickImg {
    max-width: 20px;
    max-height: 20px;
}

.comments {
    margin-left: 32px;
    margin-top: 5px;
}

.myDropzone {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #9A9EA5;
}

aside {
    justify-content: center;
}

.dropzoneDivider {
    width: 100%;
    margin-bottom: 34px;
}

.arr-right {
    margin-left: 8px;
}
.arr-left {
    margin-right: 8px;
}

.dateInput button {
    display: none;
}

.rowElem {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none!important;
}

.MuiOutlinedInput-notchedOutline {
    border: none!important;
}

#form1, #form2, #form3 {
    animation: slide-and-fade 0.3s ease-in-out;
}

@keyframes slide-and-fade {
    0% {
        opacity: 0.2;
        transform: translateY(3%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@media (max-width:768px) {
    .top {
      zoom: 65%;
      transform: scale(0.65);
      -webkit-text-size-adjust: none;
      -moz-text-size-adjust: none;
    }
    .logo {
      max-width: 40%;
    }

    .toast {
      max-width: 200px;
      max-height: 50px;
      font-size: 12px;
    }
  }