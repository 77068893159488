body {
    background-color: #f5f5f5;
    font-family: 'Poppins';
}

.successContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    padding-inline: 40px;
    justify-content: space-evenly;
}

.successImg {
    max-width: 300px;
}

.successHeader {
    font-family: 'Poppins';
    font-size: 40px;
    font-weight: 700;
    color: black;
    margin-bottom: 27px;
}

.successTitle {
    font-size: 40px;
    font-weight: 700;
    color: #242731;
    margin-bottom: 10px;
}

.successSubtitle {
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 300;
    color: #575F6E;
}

@media (max-width:768px) {
    .header, .title {
        font-size: 24px;
    }
    .subtitle {
        font-size: 16px;
    }
}